import { createContext, useCallback, useMemo, useState, useEffect } from 'react';
import {
  BusinessSegment,
  Product,
  ProductChoiceData,
  ProductFilter,
  ProductFilterData,
  ProductGroup,
  Subject,
} from '../types';
import { useAuthContext } from '../api/useAuthContext';
import { useApiContext } from '../api/useApiContext';
import { useAppContext } from './useAppProvider';

export const ProductHandlerContext = createContext(
  {} as ReturnType<typeof useProductHandler>,
);

export const ProductHandlerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const productHandler = useProductHandler();

  return (
    <ProductHandlerContext.Provider value={productHandler}>
      {children}
    </ProductHandlerContext.Provider>
  );
};

const useProductHandler = () => {
  const { userSettings } = useAuthContext();
  const { getProductMetadata } = useApiContext();
  const { dispatch, currentChat, productFilter } = useAppContext();
  const [productData, setProductData] = useState<ProductChoiceData | null>(
    null,
  );

  const [filteredProductData, setFilteredProductData] =
    useState<ProductChoiceData | null>(null);
  const [productChoices, setProductChoices] = useState<Product[] | undefined>();

  const specifiedSubjects = useMemo(() => {
    return (
      currentChat.user_specified?.reduce(
        (acc, subject) => {
          if (acc.some((s) => s.product_name === subject.product_name))
            return acc;
          return [...acc, subject];
        },
        currentChat.subjects ? [...currentChat.subjects] : [],
      ) ??
      currentChat.subjects ??
      []
    );
  }, [currentChat.user_specified, currentChat.subjects]);

  const maxProductSelections = 2;
  const maxProductSelectionsReached =
    specifiedSubjects.length >= maxProductSelections;

  const canAddNewProduct = useMemo(
    () => !maxProductSelectionsReached && productChoices !== undefined,
    [maxProductSelectionsReached, productChoices],
  );

  const selectedProducts = useMemo(
    () =>
      productData?.product_names.filter((product) =>
        specifiedSubjects.some(
          (subject) => subject.product_name === product.product_name,
        ),
      ),
    [productData?.product_names, specifiedSubjects],
  );

  const getProductFromId = useCallback(
    (product_name?: string) => {
      if (!product_name || !productData) return;
      return productData?.product_names.find(
        (product) => product.product_name === product_name,
      );
    },
    [productData],
  );

  const getProductsFromList = useCallback(
    (subjects: Subject[]): Product[] => {
      return subjects.reduce((acc: Product[], option: Subject) => {
        const product = getProductFromId(option.product_name);
        if (product) acc.push(product);
        return acc;
      }, []);
    },
    [getProductFromId],
  );

  const getProductGroupFromId = useCallback(
    (product_group?: string) => {
      if (!product_group || !productData) return;
      return productData?.product_groups.find(
        (group) => group.product_group === product_group,
      );
    },
    [productData],
  );

  const getBrandFromId = useCallback(
    (brand?: string) => {
      if (!brand || !productData) return;
      return productData?.brands.find((b) => b.brand === brand);
    },
    [productData],
  );

  const getProductFilterData = useCallback(
    (productFilter?: ProductFilter): ProductFilterData | undefined => {
      if (!productFilter) return undefined;
      const product_name = getProductFromId(productFilter.product_name);
      const product_group = getProductGroupFromId(productFilter.product_group);
      const brand = getBrandFromId(productFilter.brand);
      return { product_name, product_group, brand };
    },
    [getBrandFromId, getProductFromId, getProductGroupFromId],
  );

  const filterProductGroups = useCallback(
    (product_groups: ProductGroup[], brand?: string) => {
      if (!brand) return product_groups;
      return product_groups.filter((group) => group.brands?.includes(brand));
    },
    [],
  );

  const filterProducts = useCallback(
    (products: Product[], selection?: Partial<Product>) => {
      if (!selection)
        return products.filter(
          (product) =>
            product.region === userSettings?.region || product.region === 'all',
        );
      const filteredProducts = products.filter((product) => {
        for (const [key, value] of Object.entries(selection)) {
          if (!value || key === 'product_name' || key === 'region') continue;
          if (product[key as keyof Product] !== value) return false;
        }
        const regionMatch =
          product.region === userSettings?.region || product.region === 'all';
        return regionMatch ? true : false;
      });
      return filteredProducts;
    },
    [userSettings?.region],
  );

  const buildRelatedProductData = (
    products: Product[],
    productData: ProductChoiceData,
  ): ProductChoiceData => {
    const product_names = products;
    const product_groups = productData.product_groups.filter((group) =>
      product_names.some(
        (product) => product.product_group === group.product_group,
      ),
    );
    const brands = productData.brands.filter((brand) =>
      product_names.some((product) => product.brand === brand.brand),
    );
    const product_types = productData.product_types.filter((type) =>
      product_names.some(
        (product) => product.product_type === type.product_type,
      ),
    );
    return { product_names, product_groups, brands, product_types };
  };

  const getProductDataToFilter = useCallback(
    (productData: ProductChoiceData) => {
      if (specifiedSubjects.length > 0 && productChoices) {
        return buildRelatedProductData(productChoices, productData);
      }
      return productData;
    },
    [specifiedSubjects, productChoices],
  );

  const setupRelatedProductData = (products: Product[]) => {
    updateProductChoices(products);
    productData &&
      setFilteredProductData(buildRelatedProductData(products, productData));
  };

  const updateFilteredProductData = useCallback(
    (filter?: ProductFilter) => {
      if (!productData) return;
      const { product_names, product_groups } =
        getProductDataToFilter(productData);
      const filteredProductGroups = filterProductGroups(
        product_groups,
        filter?.brand,
      );
      const filteredProducts = filterProducts(product_names, filter);
      setFilteredProductData({
        brands: productData.brands,
        product_types: productData.product_types,
        product_groups: filteredProductGroups,
        product_names: filteredProducts,
      });
    },
    [productData, getProductDataToFilter, filterProductGroups, filterProducts],
  );

  const getSubjectFromId = useCallback(
    (product_name?: string): Subject | undefined => {
      const product = getProductFromId(product_name);
      if (!product) return;
      const { brand, product_group, product_type, region } = product;
      return { brand, product_group, product_type, product_name, region };
    },
    [getProductFromId],
  );

  const getSubjectFromProduct = (product: Product): Subject => {
    return {
      brand: product.brand,
      product_type: product.product_type,
      product_group: product.product_group,
      product_name: product.product_name,
      region: product.region,
    };
  };

  const getSubjectsFromList = (products: Product[]): Subject[] => {
    return products.reduce((acc: Subject[], product: Product) => {
      const subject = getSubjectFromProduct(product);
      acc.push(subject);
      return acc;
    }, []);
  };

  const getProductSubheader = useCallback((product: Product) => {
    const { product_type_display_name, brand_display_name } = product;
    const subheader = [product_type_display_name, brand_display_name]
      .filter(Boolean)
      .join(' / ');
    return subheader;
  }, []);

  const productGroupMatchesBrand = (
    product_group?: string,
    brand?: string,
  ): string | undefined => {
    if (!product_group) return;
    if (!brand) return product_group;
    const productGroup = getProductGroupFromId(product_group);
    if (!productGroup) return;
    if (productGroup.brands?.includes(brand)) return product_group;
  };

  const productNameMatchesBrand = (
    product_name?: string,
    brand?: string,
  ): string | undefined => {
    if (!product_name) return;
    if (!brand) return product_name;
    const product = getProductFromId(product_name);
    if (!product) return;
    if (product.brand === brand) return product_name;
  };

  const brandMatchesProductGroup = (
    brand?: string,
    product_group?: string,
  ): string | undefined => {
    if (!brand) return;
    if (!product_group) return brand;
    const productGroup = getProductGroupFromId(product_group);
    if (!productGroup) return;
    if (productGroup.brands?.includes(brand)) return brand;
  };

  const productNameMatchesProductGroup = (
    product_name?: string,
    product_group?: string,
  ): string | undefined => {
    if (!product_name) return;
    if (!product_group) return product_name;
    const product = getProductFromId(product_name);
    if (!product) return;
    if (product.product_group === product_group) return product_name;
  };

  const setProductFilter = useCallback(
    (filter?: ProductFilter) => {
      updateFilteredProductData(filter);
      dispatch({ type: 'SET_PRODUCT_FILTER', payload: filter });
    },
    [dispatch, updateFilteredProductData],
  );

  const clearProductFilter = useCallback(() => {
    dispatch({ type: 'SET_PRODUCT_FILTER', payload: undefined });
    setFilteredProductData(productData);
  }, [dispatch, productData]);

  const setProductFilterFromProduct = (product?: Subject) => {
    setProductFilter({
      brand: productFilter?.brand ?? product?.brand,
      product_group: productFilter?.product_group ?? product?.product_group,
      product_name: product?.product_name,
    });
  };

  const updateProductFilter = (id: keyof ProductFilter, value?: string) => {
    switch (id) {
      case 'brand':
        setProductFilter({
          brand: value,
          product_group: productGroupMatchesBrand(
            productFilter?.product_group,
            value,
          ),
          product_name: productNameMatchesBrand(
            productFilter?.product_name,
            value,
          ),
        });
        break;
      case 'product_group':
        setProductFilter({
          brand: brandMatchesProductGroup(productFilter?.brand, value),
          product_group: value,
          product_name: productNameMatchesProductGroup(
            productFilter?.product_name,
            value,
          ),
        });
        break;
      case 'product_name':
        {
          const product = getProductFromId(value);
          setProductFilterFromProduct(product);
        }
        break;
      default:
        break;
    }
  };

  const removeSubjectAtIndex = (array: Subject[], index: number) => {
    if (index < 0 || index >= array.length) return array;
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i !== index) {
        newArray.push(array[i]);
      }
    }
    return newArray;
  };

  const clearSelectedProductByIndex = (index: number) => {
    if (!currentChat.user_specified) return;
    const newProductList = removeSubjectAtIndex(
      currentChat.user_specified,
      index,
    );
    dispatch({ type: 'SET_USER_SPECIFIED', payload: newProductList });
  };

  const clearAllProductSelections = () => {
    dispatch({ type: 'SET_USER_SPECIFIED', payload: undefined });
  };

  const mergeProductFilterAndSubjects = useCallback(
    (user_specified?: Subject[]): Subject[] | undefined => {
      if (!productFilter || maxProductSelectionsReached) return user_specified;
      const subject = getSubjectFromId(productFilter?.product_name);
      const newSubject: Subject = subject ?? productFilter;
      clearProductFilter();
      if (!user_specified) return [newSubject];
      return [...user_specified, newSubject];
    },
    [
      clearProductFilter,
      getSubjectFromId,
      maxProductSelectionsReached,
      productFilter,
    ],
  );

  const setProductDataFromFilter = useCallback(
    async (data_filter: BusinessSegment[]) => {
      const productData = await getProductMetadata(data_filter);
      if (productData) {
        setProductData(productData);
        setFilteredProductData(productData);
        dispatch({ type: 'SET_PRODUCT_FILTER', payload: undefined });
      }
    },
    [getProductMetadata, dispatch],
  );

  // Check if data filters are matching between user settings and chat.
  const dataFiltersAreMatching = useCallback(() => {
    return (
      currentChat.data_filter &&
      userSettings?.data_filter &&
      currentChat.data_filter.toString() ===
        userSettings?.data_filter.toString()
    );
  }, [currentChat.data_filter, userSettings?.data_filter]);

  const updateProductChoices = useCallback((products?: Product[]) => {
    setProductChoices(products);
  }, []);

  const addSubjectsToBeAddedToChoices = (subjects: Subject[]) => {
    console.log(subjects);
  };

  useEffect(() => {
    if (!currentChat?.id) {
      setFilteredProductData(productData);
      setProductChoices(undefined);
    }
  }, [currentChat?.id, productData]);

  return {
    filteredProductData,
    selectedProducts,
    setProductFilterFromProduct,
    clearSelectedProductByIndex,
    clearAllProductSelections,
    getSubjectFromId,
    getSubjectFromProduct,
    getSubjectsFromList,
    getProductsFromList,
    getProductFromId,
    getProductSubheader,
    updateProductFilter,
    clearProductFilter,
    setProductDataFromFilter,
    dataFiltersAreMatching,
    getProductFilterData,
    mergeProductFilterAndSubjects,
    productChoices,
    updateProductChoices,
    setupRelatedProductData,
    canAddNewProduct,
    addSubjectsToBeAddedToChoices,
    productData,
  };
};