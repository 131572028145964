import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppWithAuthentication } from './App.tsx';
import './index.scss';
import { PublicClientApplication } from '@azure/msal-browser';
import './i18n';

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.VITE_AZURE_AD_TENANT_ID
    }`,
    redirectUri: window.location.origin,
  },
};
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppWithAuthentication msalInstance={msalInstance} />
  </React.StrictMode>,
);
