import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../api/useAuthContext';
import { useApiContext } from '../../api/useApiContext';
import { useProductHandler } from '../../state/useProductHandler';
import { CustomDropdown } from '../ custom-dropdown';
import { RequestFormSuccess } from './RequestFormSuccess';

interface RequestFormProps {
  onClose: () => void;
}

export const RequestForm = ({ onClose }: RequestFormProps) => {
  const { t } = useTranslation();
  const { userSettings, userData } = useAuthContext();
  const { createInformationRequest } = useApiContext();
  const { filteredProductData } = useProductHandler();

  const [formData, setFormData] = useState({
    brand: '',
    product: '',
    information: '',
    product_group: ''
  });
  const [errors, setErrors] = useState({
    brand: false,
    product: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hideSubmitMessage, setHideSubmitMessage] = useState(false);
  const [includeEmail, setIncludeEmail] = useState(true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const newErrors = {
      brand: !formData.brand,
      product: !formData.product
    };
    
    setErrors(newErrors);
    
    if (!newErrors.brand && !newErrors.product) {
      setIsSubmitting(true);
      try {
        await createInformationRequest({
          business_segment: userSettings?.data_filter?.toString() || '',
          email: includeEmail ? (userData?.email || '') : '',
          brand: formData.brand,
          product: formData.product,
          comment: formData.information || undefined,
          product_group: formData.product_group || undefined
        });
        setSubmitted(true);
        setTimeout(() => {
          setHideSubmitMessage(true);
          setTimeout(() => onClose(), 300);
        }, 4000);
      } catch (error) {
        console.error('Failed to submit request:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return submitted ? (
    <RequestFormSuccess hideMessage={hideSubmitMessage} />
  ) : (
    <div className="request-form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label>{t('information_menu.request_form.business_segment')}</label>
          <input value={userSettings?.data_filter || ''} disabled />
        </div>
        <div className="form-field">
          <label>{t('information_menu.request_form.brand')}</label>
          <input
            className={errors.brand ? 'error' : ''}
            value={formData.brand}
            onChange={(e) => setFormData({...formData, brand: e.target.value})}
          />
        </div>
        <div className="form-field dropdown-field">
          <label>{t('product_filter.product_group')}</label>
          <CustomDropdown
            name={t('product_filter.product_group')}
            options={filteredProductData?.product_groups?.map((group) => ({
              value: group.product_group,
              label: group.display_name,
            })) || []}
            onChange={(value) => setFormData({...formData, product_group: value?.value || ''})}
            value={formData.product_group}
            metricsId="RequestFormProductGroup"
            menuPlacement="bottom"
            useFloatingLabel={false}
          />
        </div>
        <div className="form-field">
          <label>{t('information_menu.request_form.product')}</label>
          <input
            className={errors.product ? 'error' : ''}
            value={formData.product}
            onChange={(e) => setFormData({...formData, product: e.target.value})}
          />
        </div>
        <div className="form-field">
          <label>{t('information_menu.request_form.information')}</label>
          <textarea
            value={formData.information}
            onChange={(e) => setFormData({...formData, information: e.target.value})}
          />
        </div>
        <div className="form-field">
          <div className="email-consent-field">
            <input
              type="checkbox"
              id="include-email"
              checked={includeEmail}
              onChange={(e) => setIncludeEmail(e.target.checked)}
            />
            <label htmlFor="include-email" className="checkbox-label">
              {t('information_menu.request_form.include_email_label', {
                email: userData?.email
              })}
            </label>
          </div>
        </div>
        <div className="form-actions">
          <button type="button" onClick={onClose} disabled={isSubmitting}>
            {t('common.cancel')}
          </button>
          <button type="submit" disabled={isSubmitting}>
            {t('common.send')}
          </button>
        </div>
      </form>
    </div>
  );
};